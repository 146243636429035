import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Select,
  Modal,
  Table,
  DatePicker,
  Form,
  message,
} from "antd";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  EditOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import style from "./SchoolUserList.module.css";
import { $userListEdu, getUserListEduFx } from "../../models/userListModel";
import { useUnit } from "effector-react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Checkbox } from "antd";

dayjs.extend(customParseFormat);

const { Search } = Input;
const { Option } = Select;

function EmployeeList() {
  const employeeList = useUnit($userListEdu); // Data source
  const loading = useUnit(getUserListEduFx.pending);

  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]); // Selected departments
  const [layout, setLayout] = useState("cards"); // State for layout toggle

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm(); // Form instance
  const [showCuratorsOnly, setShowCuratorsOnly] = useState(false);
  const [uploadedPhotoBase64, setUploadedPhotoBase64] = useState(null);

  const handlePhotoUpload = ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj || fileList[0];

      // Convert photo to Base64
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedPhotoBase64(e.target.result);
        message.success("Photo uploaded successfully!");
      };
      reader.readAsDataURL(file);
    } else {
      setUploadedPhotoBase64(null); // Clear photo if removed
      message.info("Photo removed.");
    }
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split(".");
    return new Date(`${year}-${month}-${day}`);
  };

  const [isAddEmployeeModalVisible, setIsAddEmployeeModalVisible] =
    useState(false);

  const handleAddNewEmployee = () => {
    form.resetFields(); // Clear the form fields
    setIsAddEmployeeModalVisible(true); // Show the modal
  };

  const handleSaveNewEmployee = async (values) => {
    if (!uploadedPhotoBase64) {
      message.error(
        "Пожалуйста загрузите фото сотрудника перед его добавлением."
      );
      return;
    }

    const formattedDateOfBirth = values.date_of_birth
      ? dayjs(values.date_of_birth).format("DD.MM.YYYY")
      : null;

    const requestBody = {
      place_id: "zasa05e1p1",
      properties: {
        emp_type: values.emp_type,
        date_of_birth: formattedDateOfBirth,
        emp_category: "Сотрудник",
        emp_department: values.emp_department ? [values.emp_department] : [],
        emp_position: values.emp_position
          ? values.emp_position.split(",").map((pos) => pos.trim())
          : [],
        subject: values.subject
          ? values.subject.split(",").map((sub) => sub.trim())
          : [],
        user_email: values.user_email || "-",
        user_gender: values.user_gender,
        work_type: values.work_type,
        user_address: values.user_address,
        user_mobile: values.user_mobile,
        created_type: "face-onboarding",
      },
      user_name: values.user_name,
      user_surname: values.user_surname,
      data_url: [uploadedPhotoBase64], // Include the photo as Base64
    };

    console.log("📤 Sending Add Employee API Request:", requestBody);

    try {
      const response = await fetch(
        "https://ste1h7g7s7.execute-api.eu-west-1.amazonaws.com/LoyaltySystem1/UserRecords",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add new employee.");
      }

      const responseData = await response.json();
      console.log("📥 API Response:", responseData);

      if (responseData.statusCode === 200) {
        message.success("Сотрудник добавлен успешно!");
        setIsAddEmployeeModalVisible(false);
        form.resetFields();
        setUploadedPhotoBase64(null); // Reset uploaded photo state
        getUserListEduFx(); // Refresh the employee list
      } else {
        throw new Error(
          responseData.body || "Ошибка при добавлении сотрудника"
        );
      }
    } catch (error) {
      console.error("Ошибка в добавлении сотрудника:", error);
      message.error(
        "Ошибка при добавлении сотрудника. Пожалуйста, повторите попытку."
      );
    }
  };

  const handleEdit = (employee) => {
    const empPositionString = employee.emp_position
      ? employee.emp_position.join(", ")
      : "";

    form.setFieldsValue({
      ...employee,
      emp_position: empPositionString, // Convert array to string
      date_of_birth: employee.date_of_birth
        ? dayjs(employee.date_of_birth, "DD.MM.YYYY")
        : null,
    });

    setEditingRecord(employee);
    setIsEditModalVisible(true);
  };

  const [editLoading, setEditLoading] = useState(false);

  const handleSaveEdit = async (values) => {
    const {
      user_name,
      user_surname,
      emp_department,
      emp_position,
      emp_type,
      user_email,
      date_of_birth,
      user_address,
      user_mobile,
    } = values;

    if (!editingRecord?.partition_key || !editingRecord?.sort_key) {
      message.error("Invalid editing record. Please refresh and try again.");
      return;
    }

    const formattedDateOfBirth = date_of_birth
      ? dayjs(date_of_birth).format("DD.MM.YYYY")
      : "";

    // Convert emp_position string to an array
    const empPositionArray = values.emp_position
      ? values.emp_position.split(",").map((pos) => pos.trim())
      : [];

    const requestBody = {
      type: "employee",
      partition_key: editingRecord.partition_key,
      sort_key: editingRecord.sort_key,
      data: {
        user_name: values.user_name,
        user_surname: values.user_surname,
        emp_department: values.emp_department,
        emp_position: empPositionArray, // Send as array
        emp_type: values.emp_type,
        date_of_birth: formattedDateOfBirth,
        user_address: values.user_address,
        user_mobile: values.user_mobile,
        user_email: values.user_email,
      },
    };

    console.log("📤 Sending Employee Editing API Request:", requestBody);

    setEditLoading(true);
    try {
      const response = await fetch(
        "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/ChangeEDUInfo2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const rawResponse = await response.text();
      console.log("📥 Raw API Response:", rawResponse);

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status} - ${rawResponse}`);
      }

      const data = JSON.parse(rawResponse);
      if (data?.statusCode === 200) {
        message.success("Данные сотрудника успешно обновлены!");
        // setIsEditModalVisible(false);
        // form.resetFields();
        // getUserListEduFx(); // Refresh employee list
      } else {
        throw new Error(data?.message || "Неизвестная ошибка на сервере");
      }
    } catch (error) {
      message.error(`Ошибка: ${error.message}`);
      console.error("❌ Error in API call:", error);
    } finally {
      setEditLoading(false);
    }
  };

  useEffect(() => {
    getUserListEduFx(); // Fetch data on mount
  }, []);

  // Get unique departments for dropdown options
  const uniqueDepartments = useMemo(() => {
    const departments = employeeList.flatMap(
      (employee) => employee.emp_department || []
    );
    return [...new Set(departments)];
  }, [employeeList]);

  // Get unique departments for dropdown options
  const uniqueEmpType = useMemo(() => {
    const empTypes = employeeList.flatMap(
      (employee) => employee.emp_type || []
    );
    return [...new Set(empTypes)];
  }, [employeeList]);

  const filteredEmployees = useMemo(() => {
    return employeeList
      .filter((employee) => {
        if (!employee || employee.emp_category !== "Сотрудник") return false;

        const matchesSearch = Object.values(employee)
          .join(" ")
          .toLowerCase()
          .includes(searchText.toLowerCase());

        const matchesDepartment =
          selectedDepartments.length === 0 ||
          (Array.isArray(employee.emp_department) &&
            employee.emp_department.some((dept) =>
              selectedDepartments.includes(dept)
            ));

        const matchesCuratorFilter =
          !showCuratorsOnly ||
          (Array.isArray(employee.emp_position) &&
            employee.emp_position.includes("Куратор"));

        return matchesSearch && matchesDepartment && matchesCuratorFilter;
      })
      .sort((a, b) => {
        if (sortOption === "name-asc") {
          return a.user_name.localeCompare(b.user_name, "ru");
        } else if (sortOption === "name-desc") {
          return b.user_name.localeCompare(a.user_name, "ru");
        }
        return 0;
      });
  }, [
    employeeList,
    searchText,
    sortOption,
    selectedDepartments,
    showCuratorsOnly,
  ]);

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title}>Список сотрудников</div>

      {/* Top Controls */}
      <div className={style.user_list_top_wrapper}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px", // Spacing between elements
            flexWrap: "wrap", // Ensure it wraps on smaller screens
            marginBottom: "16px", // Add spacing below the filter section
          }}
        >
          {/* Search Bar */}
          <Search
            placeholder="Поиск по любому параметру"
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: "250px" }} // Consistent width
          />

          {/* Sort Dropdown */}
          <Select
            placeholder="Сортировка"
            onChange={setSortOption}
            style={{ width: "200px" }} // Consistent width
          >
            <Option value="name-asc">Имя: А-Я</Option>
            <Option value="name-desc">Имя: Я-А</Option>
          </Select>

          {/* Department Filter */}
          <Select
            mode="multiple"
            placeholder="Фильтр по отделу"
            value={selectedDepartments}
            onChange={setSelectedDepartments}
            style={{ width: "250px" }}
            allowClear
          >
            {uniqueDepartments.map((dept) => (
              <Option key={dept} value={dept}>
                {dept}
              </Option>
            ))}
          </Select>

          {/* Checkbox for Curator Filter */}
          <Checkbox
            checked={showCuratorsOnly}
            onChange={(e) => setShowCuratorsOnly(e.target.checked)}
          >
            Показывать только Кураторов
          </Checkbox>

          {/* Add Employee Button */}
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            onClick={handleAddNewEmployee}
          >
            Добавить сотрудника
          </Button>

          {/* Layout Toggle Buttons */}
          <div>
            <Button
              onClick={() => setLayout("cards")}
              icon={<AppstoreOutlined />}
              type={layout === "cards" ? "primary" : "default"}
              style={{ marginRight: "8px" }}
            />
            <Button
              onClick={() => setLayout("list")}
              icon={<UnorderedListOutlined />}
              type={layout === "list" ? "primary" : "default"}
            />
          </div>
        </div>
      </div>

      {/* Employees View */}
      {layout === "cards" ? (
        <div
          className={style.cards_wrapper}
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          {filteredEmployees.map((employee) => (
            <div
              key={employee.id}
              style={{
                position: "relative",
                border: "1px solid #ddd",
                borderRadius: "10px",
                width: "45%",
                minWidth: "350px",
                display: "flex",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                backgroundColor: "#fff",
              }}
            >
              {/* Edit Button */}
              <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(employee)}
                  style={{ backgroundColor: "#007bff", color: "white" }}
                />
              </div>

              {/* Photo Section */}
              <div style={{ padding: "15px" }}>
                <img
                  src={employee.presigned_url || "/default_employee_image.png"}
                  alt="Employee"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "3px solid #007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Modal.info({
                      title: "Просмотр изображения",
                      content: (
                        <img
                          src={
                            employee.presigned_url ||
                            "/default_employee_image.png"
                          }
                          alt="Enlarged Student"
                          style={{ width: "100%" }}
                        />
                      ),
                      icon: null,
                    });
                  }}
                />
              </div>

              {/* Details Section */}
              <div style={{ flex: "1", padding: "15px" }}>
                <h3 style={{ margin: "0 0 10px", fontSize: "18px" }}>
                  {`${employee.user_name} ${employee.user_surname}`}
                </h3>
                <div>
                  <b>Отдел:</b> {employee.emp_department}
                </div>
                <div>
                  <b>Должность:</b> {employee.emp_position.join(", ")}
                </div>
                <div>
                  <b>Статус:</b> {employee.emp_type}
                </div>

                {employee.emp_position.includes("Куратор") && (
                  <div>
                    <b>Класс:</b> {employee.grade_number}
                    {employee.grade_letter}
                  </div>
                )}
                <div>
                  <b>Дата рождения:</b> {employee.date_of_birth}
                </div>
                <div>
                  <b>Номер телефона:</b> {employee.user_mobile}
                </div>
                <div>
                  <b>Адрес:</b> {employee.user_address}
                </div>
                <div>
                  <b>Email:</b> {employee.user_email}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Table
          dataSource={filteredEmployees}
          rowKey="id"
          loading={loading}
          columns={[
            {
              title: "№",
              key: "index",
              render: (text, record, index) => index + 1,
              width: 50,
            },
            { title: "Имя", dataIndex: "user_name", key: "user_name" },
            {
              title: "Фамилия",
              dataIndex: "user_surname",
              key: "user_surname",
            },
            {
              title: "Отдел",
              dataIndex: "emp_department",
              key: "emp_department",
            },
            {
              title: "Должность",
              dataIndex: "emp_position",
              key: "emp_position",
            },
            {
              title: "Статус",
              dataIndex: "emp_type",
              key: "emp_type",
            },
            {
              title: "Класс",
              key: "grade_info",
              render: (text, record) =>
                record.emp_position.includes("Куратор") ? (
                  <span>
                    {record.grade_number}
                    {record.grade_letter}
                  </span>
                ) : (
                  "-"
                ),
            },
            {
              title: "Дата рождения",
              dataIndex: "date_of_birth",
              key: "date_of_birth",
            },
            {
              title: "Номер телефона",
              dataIndex: "user_mobile",
              key: "user_mobile",
            },
            {
              title: "Адрес",
              dataIndex: "user_address",
              key: "user_address",
            },
            { title: "Email", dataIndex: "user_email", key: "user_email" },
            {
              title: "Действия",
              key: "actions",
              render: (record) => (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(record)}
                >
                  Редактировать
                </Button>
              ),
            },
          ]}
        />
      )}

      <Modal
        title="Редактирование Сотрудника"
        visible={isEditModalVisible}
        onCancel={() => {
          setIsEditModalVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSaveEdit}>
          <Form.Item
            label="Имя"
            name="user_name"
            rules={[{ required: true, message: "Пожалуйста введите имя" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="user_surname"
            rules={[{ required: true, message: "Пожалуйста введите фамилию" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Отдел"
            name="emp_department"
            rules={[{ required: true, message: "Пожалуйста выберите отдел" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Должность(и)"
            name="emp_position"
            rules={[
              {
                required: true,
                message:
                  "Пожалуйста введите должность(и), разделенные запятыми",
              },
            ]}
          >
            <Input placeholder="Пожалуйста введите должность(и), разделенные запятыми" />
          </Form.Item>
          <Form.Item
            label="Статус"
            name="emp_type"
            rules={[{ required: true, message: "Пожалуйста выберите статус" }]}
          >
            <Select placeholder="Выберите статус">
              {uniqueEmpType.map((empType) => (
                <Option key={empType} value={empType}>
                  {empType}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Дата рождения"
            name="date_of_birth"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите или введите дату рождения",
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      "Пожалуйста, выберите или введите дату."
                    );
                  }
                  if (!dayjs(value, "DD.MM.YYYY", true).isValid()) {
                    return Promise.reject(
                      "Некорректный формат даты (ДД.ММ.ГГГГ)."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              format="DD.MM.YYYY"
              style={{ width: "100%" }}
              placeholder="Введите или выберите дату"
              value={form.getFieldValue("date_of_birth")}
              onChange={(date) => form.setFieldsValue({ date_of_birth: date })}
              onBlur={(e) => {
                const enteredDate = e.target.value;
                if (dayjs(enteredDate, "DD.MM.YYYY", true).isValid()) {
                  form.setFieldsValue({
                    date_of_birth: dayjs(enteredDate, "DD.MM.YYYY"),
                  });
                } else if (enteredDate) {
                  message.error(
                    "Некорректный формат даты. Используйте ДД.ММ.ГГГГ"
                  );
                }
              }}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>
          <Form.Item
            label="Адрес"
            name="user_address"
            rules={[{ required: true, message: "Пожалуйста введите адрес" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Номер телефона"
            name="user_mobile"
            rules={[
              { required: true, message: "Пожалуйста введите номер телефона" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="user_email"
            rules={[
              { required: false, message: "Пожалуйста введите почтовый адрес" },
              {
                type: "email",
                message: "Пожалуйста введите валидный почтовый адрес",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Добавить нового сотрудника"
        visible={isAddEmployeeModalVisible}
        onCancel={() => setIsAddEmployeeModalVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSaveNewEmployee}>
          <Form.Item label="Фото Сотрудника">
            <Upload
              name="photo"
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Prevent auto-upload
              onChange={handlePhotoUpload}
              accept="image/*"
            >
              <Button icon={<UploadOutlined />}>
                Загрузить фото (.jpg/jpeg)
              </Button>
            </Upload>
            {uploadedPhotoBase64 && (
              <div style={{ marginTop: 10 }}>
                <img
                  src={uploadedPhotoBase64}
                  alt="Preview"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
            )}
          </Form.Item>

          <Form.Item
            label="Имя"
            name="user_name"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите имя",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="user_surname"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите фамилию",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Отдел"
            name="emp_department"
            rules={[{ required: true, message: "Пожалуйста выберите отдел" }]}
          >
            <Select placeholder="Выберите отдел">
              {uniqueDepartments.map((dept) => (
                <Option key={dept} value={dept}>
                  {dept}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Должность(и)"
            name="emp_position"
            rules={[
              { required: true, message: "Пожалуйста введите должность(и)" },
            ]}
          >
            <Input placeholder="Пожалуйста введите должность(и), разделенными запятыми" />
          </Form.Item>

          <Form.Item
            label="Статус"
            name="emp_type"
            rules={[{ required: true, message: "Пожалуйста выберите статус" }]}
          >
            <Select placeholder="Выберите статус">
              {uniqueEmpType.map((empType) => (
                <Option key={empType} value={empType}>
                  {empType}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Дата рождения"
            name="date_of_birth"
            rules={[
              { required: true, message: "Пожалуйста выберите дату рождения" },
            ]}
          >
            <DatePicker
              format="DD.MM.YYYY"
              style={{ width: "100%" }}
              placeholder="Выберите или введите дату"
              onChange={(date) => form.setFieldsValue({ date_of_birth: date })}
              onBlur={(e) => {
                const enteredDate = e.target.value;
                if (dayjs(enteredDate, "DD.MM.YYYY", true).isValid()) {
                  form.setFieldsValue({
                    date_of_birth: dayjs(enteredDate, "DD.MM.YYYY"),
                  });
                } else if (enteredDate) {
                  message.error(
                    "Некорректный формат даты. Используйте ДД.ММ.ГГГГ"
                  );
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Адрес"
            name="user_address"
            rules={[{ required: true, message: "Пожалуйста введите адрес" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Номер телефона"
            name="user_mobile"
            rules={[
              { required: true, message: "Пожалуйста введите номер телефона" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="user_email"
            rules={[
              { required: false, message: "Пожалуйста введите почтовый адрес" },
              {
                type: "email",
                message: "Пожалуйста введите валидный почтовый адрес",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default EmployeeList;
