import { createStore } from "effector";

export const $userList = createStore([]);
// $userList.watch((state) => console.log("$userList", state));
$userList.watch((state) => {
  console.log("Current user list state:", state);
});

export const $userListPlaceId = createStore("amaa25d7c9");
$userListPlaceId.watch((state) => console.log("$userListPlaceId", state));
export const $chosenUser = createStore(null);
$chosenUser.watch((state) => console.log("$chosenUser", state));

export const $potentialEmp = createStore(null);
$potentialEmp.watch((state) => console.log("$potentialEmp", state));

export const $showCreateModal = createStore(false);
$showCreateModal.watch((state) => console.log("$showCreateModal", state));

export const $chosenPotentialEmp = createStore(null);
$chosenPotentialEmp.watch((state) => console.log("$chosenPotentialEmp", state));

export const $chosenEmpImgModal = createStore(null);

export const $userListEdu = createStore([]);
$userListEdu.watch((state) => console.log("$userListEdu", state));

export const $userEmpCategory = createStore("Сотрудник");

export const $importFileModal = createStore(false);

export const $importFilesErrors = createStore([]);

export const $potentialEmpEdu = createStore([]);
$potentialEmpEdu.watch((state) => console.log("$potentialEmpEdu", state));

export const $chosenPotentialEmpEdu = createStore(null);

export const $chosenEmpEduImgModal = createStore(null);

export const $chosenViewEdu = createStore(null);

export const $editUserEdu = createStore(null);
