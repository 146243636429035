import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Select,
  Modal,
  Form,
  message,
  Table,
  Segmented,
} from "antd";
import {
  EditOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import style from "./SchoolUserList.module.css";
import {
  $userListEdu,
  changeShowCreateModalEv,
  getUserListEduFx,
  $userEmpCategory,
  changeUserEmpCategoryEv,
} from "../../models/userListModel";
import { useUnit } from "effector-react";

const { Search } = Input;
const { Option } = Select;

// Custom Image component for rendering presigned URLs
const ImageFromS3 = ({ presigned_url }) => {
  return (
    <img
      src={presigned_url}
      alt="Student"
      style={{
        width: 60,
        height: 60,
        borderRadius: "50%",
        objectFit: "cover",
        backgroundColor: "#f0f0f0", // Fallback background
      }}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite error loop
        e.target.src = "/default_student_image.png"; // Fallback image
      }}
    />
  );
};

function SchoolUserListRestyle() {
  const [form] = Form.useForm();
  const studentList = useUnit($userListEdu);
  const loading = useUnit(getUserListEduFx.pending);
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const userEmpCategory = useUnit($userEmpCategory);
  const changeUserEmpCategory = useUnit(changeUserEmpCategoryEv);

  const [layout, setLayout] = useState("cards");
  const [gradeFilter, setGradeFilter] = useState(null);
  const [dobRange, setDobRange] = useState([null, null]);
  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  // State for Add Student Modal
  const [isAddStudentModalVisible, setIsAddStudentModalVisible] =
    useState(false);

  // Function to handle adding a new student
  const handleAddStudent = async (values) => {
    const requestBody = {
      place_id: "zasa05e1p1",
      properties: {
        date_of_birth: values.date_of_birth,
        emp_category: "Ученик",
        emp_position: "Стандартный",
        user_email: values.user_email,
        user_gender: "default", // Replace with actual gender field if needed
        work_type: "default",
        grade: values.grade,
        shift: "default",
        contacts: [], // Add if applicable
        user_address: "Address Placeholder", // Replace if applicable
        user_mobile: "1234567890", // Replace if applicable
        created_type: "manual",
        checkin_status: "onboarded",
      },
      user_name: values.user_name,
      user_surname: values.user_surname,
      data_url: [], // Add if applicable
    };

    try {
      const response = await fetch(
        "https://ste1h7g7s7.execute-api.eu-west-1.amazonaws.com/LoyaltySystem1/UserRecords",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success("Новый ученик успешно добавлен!");
        console.log(data);
      } else {
        message.error(`Ошибка добавления: ${data.body}`);
      }
    } catch (error) {
      message.error(`Ошибка подключения: ${error.message}`);
    }
    setIsAddStudentModalVisible(false);
  };

  useEffect(() => {
    getUserListEduFx();
  }, []);

  // When editingRecord changes, reset the form with the new values
  useEffect(() => {
    if (editingRecord) {
      form.setFieldsValue({
        user_name: editingRecord.user_name,
        user_surname: editingRecord.user_surname,
        grade: editingRecord.grade,
        date_of_birth: editingRecord.date_of_birth,
        user_email: editingRecord.user_email,
      });
    }
  }, [editingRecord, form]);

  const filteredStudents = useMemo(() => {
    // Ensure we filter only "Ученик"
    return studentList
      .filter((student) => {
        if (!student || student.emp_category !== "Ученик") return false;

        const matchesSearch = Object.values(student)
          .join(" ")
          .toLowerCase()
          .includes(searchText.toLowerCase());

        const matchesGrade = gradeFilter ? student.grade === gradeFilter : true;

        const matchesDob =
          dobRange[0] && dobRange[1]
            ? new Date(
                `${student.user_birth_year}-${student.user_birth_month}-${student.user_birth_day}`
              ) >= new Date(dobRange[0]) &&
              new Date(
                `${student.user_birth_year}-${student.user_birth_month}-${student.user_birth_day}`
              ) <= new Date(dobRange[1])
            : true;

        return matchesSearch && matchesGrade && matchesDob;
      })
      .sort((a, b) => {
        if (sortOption === "name-asc") {
          return a.user_name.localeCompare(b.user_name, "ru");
        } else if (sortOption === "name-desc") {
          return b.user_name.localeCompare(a.user_name, "ru");
        } else if (sortOption === "grade-asc") {
          return a.grade.localeCompare(b.grade);
        } else if (sortOption === "grade-desc") {
          return b.grade.localeCompare(a.grade);
        } else if (sortOption === "dob-asc") {
          return (
            new Date(
              `${a.user_birth_year}-${a.user_birth_month}-${a.user_birth_day}`
            ) -
            new Date(
              `${b.user_birth_year}-${b.user_birth_month}-${b.user_birth_day}`
            )
          );
        } else if (sortOption === "dob-desc") {
          return (
            new Date(
              `${b.user_birth_year}-${b.user_birth_month}-${b.user_birth_day}`
            ) -
            new Date(
              `${a.user_birth_year}-${a.user_birth_month}-${a.user_birth_day}`
            )
          );
        }
        return 0;
      });
  }, [studentList, searchText, gradeFilter, dobRange, sortOption]);

  const handleEdit = (record) => {
    setEditingRecord(record);
    setIsEditModalVisible(true);
  };

  const handleSaveEdit = async (values) => {
    const { user_name, user_surname, grade, user_email, date_of_birth } =
      values;

    const requestBody = {
      type: "student",
      partition_key: editingRecord.partition_key, // Replace with appropriate key
      sort_key: editingRecord.sort_key, // Replace with appropriate key
      data: {
        user_name,
        user_surname,
        grade,
        user_email,
        date_of_birth,
      },
    };

    try {
      const response = await fetch(
        "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/ChangeEDUInfo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );

      // Log raw response for debugging
      const rawResponse = await response.text();
      console.log("Raw API Response:", rawResponse);

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status} - ${rawResponse}`);
      }

      // Attempt to parse JSON
      const data = JSON.parse(rawResponse);
      message.success("Данные ученика успешно обновлены!");
      console.log("Updated student data:", data);
    } catch (error) {
      message.error(`Ошибка подключения: ${error.message}`);
      console.error("Error updating student data:", error);
    }

    setIsEditModalVisible(false);
  };

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title}>Список учеников</div>
      {/* Top Controls */}
      <div className={style.user_list_top_wrapper}>
        <Segmented
          options={[{ label: "Ученик", value: "Ученик" }]}
          value={userEmpCategory}
          onChange={changeUserEmpCategory}
        />
        <Search
          placeholder="Поиск по любому параметру"
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 200 }}
        />
        <Select
          placeholder="Сортировка"
          onChange={setSortOption}
          style={{ width: 200 }}
        >
          <Option value="name-asc">Имя: А-Я</Option>
          <Option value="name-desc">Имя: Я-А</Option>
          <Option value="grade-asc">Класс: по возрастанию</Option>
          <Option value="grade-desc">Класс: по убыванию</Option>
          <Option value="dob-asc">Дата рождения: сначала старшие</Option>
          <Option value="dob-desc">Дата рождения: сначала младшие</Option>
        </Select>
        <Select
          placeholder="Фильтр по классу"
          onChange={setGradeFilter}
          allowClear
          style={{ width: 150 }}
        >
          {[...new Set(studentList.map((s) => s.grade))].map((grade) => (
            <Option key={grade} value={grade}>
              {grade}
            </Option>
          ))}
        </Select>
        <Button
          onClick={() => setLayout("cards")}
          icon={<AppstoreOutlined />}
          type={layout === "cards" ? "primary" : "default"}
        />
        <Button
          onClick={() => setLayout("list")}
          icon={<UnorderedListOutlined />}
          type={layout === "list" ? "primary" : "default"}
        />
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => setIsAddStudentModalVisible(true)} // Toggle the modal visibility
        >
          Добавить ученика
        </Button>
      </div>
      {/* List or Card View */}
      {layout === "cards" ? (
        <div
          className={style.cards_wrapper}
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          {filteredStudents.map((student) => (
            <div
              key={student.id}
              style={{
                position: "relative",
                border: "1px solid #ddd",
                borderRadius: "10px",
                width: "45%",
                minWidth: "350px",
                display: "flex",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                backgroundColor: "#fff",
              }}
            >
              {/* Edit Button */}
              <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(student)}
                  style={{ backgroundColor: "#007bff", color: "white" }}
                />
              </div>

              {/* Photo Section with click-to-view functionality */}
              <div style={{ padding: "15px" }}>
                <img
                  src={student.presigned_url || "/default_student_image.png"}
                  alt="Student"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "3px solid #007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Modal.info({
                      title: "Просмотр изображения",
                      content: (
                        <img
                          src={
                            student.presigned_url ||
                            "/default_student_image.png"
                          }
                          alt="Enlarged Student"
                          style={{ width: "100%" }}
                        />
                      ),
                      icon: null,
                    });
                  }}
                />
              </div>

              {/* Details Section */}
              <div style={{ flex: "1", padding: "15px" }}>
                <h3
                  style={{
                    margin: "0 0 10px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {`${student.user_name} ${student.user_surname}`}
                </h3>
                <div style={{ marginBottom: "8px" }}>
                  <b>Класс:</b> {student.grade}
                </div>
                <div style={{ marginBottom: "8px" }}>
                  <b>Дата рождения:</b> {student.date_of_birth}
                </div>
                <div>
                  <b>Email:</b> {student.user_email}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Table
          dataSource={filteredStudents}
          rowKey="id"
          loading={loading}
          columns={[
            {
              title: "№", // Column title
              key: "index", // Unique key for this column
              render: (text, record, index) => index + 1, // Row number (1-based index)
              width: 50, // Optional: Adjust column width
            },
            { title: "Имя", dataIndex: "user_name", key: "user_name" },
            {
              title: "Фамилия",
              dataIndex: "user_surname",
              key: "user_surname",
            },
            { title: "Класс", dataIndex: "grade", key: "grade" },
            {
              title: "Дата рождения",
              dataIndex: "date_of_birth",
              key: "date_of_birth",
            },
            { title: "Email", dataIndex: "user_email", key: "user_email" },
            {
              title: "Действия",
              key: "actions",
              render: (record) => (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(record)}
                >
                  Редактировать
                </Button>
              ),
            },
          ]}
        />
      )}
      {/* Edit Modal */}
      <Modal
        title="Редактирование ученика"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form
          form={form} // Attach the form instance
          layout="vertical"
          onFinish={handleSaveEdit}
        >
          <Form.Item label="Имя" name="user_name">
            <Input />
          </Form.Item>
          <Form.Item label="Фамилия" name="user_surname">
            <Input />
          </Form.Item>
          <Form.Item label="Класс" name="grade">
            <Input />
          </Form.Item>
          <Form.Item label="Дата рождения" name="date_of_birth">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="user_email">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* Add Student Modal */}
      <Modal
        title="Добавить нового ученика"
        visible={isAddStudentModalVisible}
        onCancel={() => setIsAddStudentModalVisible(false)}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleAddStudent}>
          <Form.Item
            label="Имя"
            name="user_name"
            rules={[{ required: true, message: "Введите имя" }]}
          >
            <Input placeholder="Имя" />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="user_surname"
            rules={[{ required: true, message: "Введите фамилию" }]}
          >
            <Input placeholder="Фамилия" />
          </Form.Item>
          <Form.Item
            label="Класс"
            name="grade"
            rules={[{ required: true, message: "Введите класс" }]}
          >
            <Input placeholder="Класс" />
          </Form.Item>
          <Form.Item
            label="Дата рождения"
            name="date_of_birth"
            rules={[{ required: true, message: "Введите дату рождения" }]}
          >
            <Input placeholder="Дата рождения" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="user_email"
            rules={[{ required: true, message: "Введите email" }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить ученика
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default SchoolUserListRestyle;
