// export const formatUserListTable = (data) => {
// 	return data.map(item => {
// 		return {
// 			key: `user-list-user-${item.partition_key.S}`,
// 			partition_key: item.partition_key ? item.partition_key.S.split('#')[1] : null,
// 			sort_key: item.sort_key ? item.sort_key.S.split('#')[1] : null,
// 			admin_place: item.admin_place ? item.admin_place.S : null,
// 			alarm: item.alarm ? item.alarm.S : null,
// 			category: item.category ? item.category.S : null,
// 			city: item.city ? item.city.S : null,
// 			emp_category: item.emp_category ? item.emp_category.S : null,
// 			emp_department: item.emp_department ? item.emp_department.S : null,
// 			emp_position: item.emp_position ? item.emp_position.S : null,
// 			place_address: item.place_address ? item.place_address.S : null,
// 			place_id: item.place_id ? item.place_id.S : null,
// 			service: item.service ? item.service.S : null,
// 			user_gender: item.user_gender ? item.user_gender.S : null,
// 			user_name: item.user_name ? item.user_name.S : null,
// 			user_nickname: item.user_nickname ? item.user_nickname.S : null,
// 			user_surname: item.user_surname ? item.user_surname.S : null,
// 			work_hours: item.work_hours ? item.work_hours.S : null,
// 			work_zones: item.work_zones ? item.work_zones.S : null,
//       days_number: item.number ? item.number.S : null,
// 		}
// 	})
// }

export const formatUserListTable = (data) => {
  return data.map((item) => {
    return {
      key: `user-list-user-${
        item.partition_key?.S || item.partition_key || "unknown"
      }`,
      partition_key: item.partition_key?.S
        ? item.partition_key.S.split("#")[1]
        : item.partition_key?.split("#")[1] || null,
      sort_key: item.sort_key?.S
        ? item.sort_key.S.split("#")[1]
        : item.sort_key?.split("#")[1] || null,
      admin_place: item.admin_place?.S || item.admin_place || null,
      alarm: item.alarm?.S || item.alarm || null,
      category: item.category?.S || item.category || null,
      city: item.city?.S || item.city || null,
      emp_category: item.emp_category?.S || item.emp_category || null,
      emp_department: item.emp_department?.S || item.emp_department || null,
      emp_position: item.emp_position?.S || item.emp_position || null,
      place_address: item.place_address?.S || item.place_address || null,
      place_id: item.place_id?.S || item.place_id || null,
      service: item.service?.S || item.service || null,
      user_gender: item.user_gender?.S || item.user_gender || null,
      user_name: item.user_name?.S || item.user_name || null,
      user_nickname: item.user_nickname?.S || item.user_nickname || null,
      user_surname: item.user_surname?.S || item.user_surname || null,
      work_hours: item.work_hours?.S || item.work_hours || null,
      work_zones: item.work_zones?.S || item.work_zones || null,
      days_number: item.number?.S || item.number || null,
      presigned_url: item.presigned_url || null,
      start_work_time: item.start_work_time?.S || item.start_work_time || null,
      end_work_time: item.end_work_time?.S || item.end_work_time || null,
    };
  });
};
