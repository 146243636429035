import "./App.css";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import {
  withAuthenticator,
  Button,
  Heading,
  Link,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import styles from "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";
import {
  fetchUserAttributes,
  fetchAuthSession,
  signIn,
  signOut,
} from "aws-amplify/auth";
// import { Auth } from 'aws-amplify';

import Foqus_logo from "./images/Foqus_white.png";
import logo_max_foqus from "./images/logo_max_foqus.svg";
import Foqus_mini from "./images/FOQUS_logo_mini.png";
import logo_mini_foqus from "./images/logo_mini_foqus.svg";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { ConfigProvider, Layout, Menu, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import SubMenu from "antd/es/menu/SubMenu";
import Icon from "antd/es/icon";
import AWS from "aws-sdk";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUnit } from "effector-react";
import {
  $userGroups,
  getPlaceIdsFx,
  setUserAttributesEv,
  setUserGroupsEv,
  setUserKeysEv,
} from "./models/userModel";
import { $isDarkTheme } from "./models/registrationModel";
import Login from "./pages/CustomLogin/CustomLogin";
import { Header } from "./pages/CustomLogin/Header";
import { SignInHeader } from "./pages/CustomLogin/SignInHeader";
import { SignInFooter } from "./pages/CustomLogin/SignInFooter";
import { Footer } from "./pages/CustomLogin/Footer";
import { getDuplicatesFx } from "./models/duplicatesModel";
import {
  disassociateUserFx,
  getSearchByIdDataFx,
} from "./models/searchByIdModel";
import { main_theme_config } from "./main_theme_config";
import { getAWSCredentials } from "./utils/utils";
import inactivityHandler from "./utils/InactivityHandler";
import { getBadImagesFx } from "./models/badImagesModel";
import { ListObjectsV2Command, S3Client } from "@aws-sdk/client-s3";
const { Sider } = Layout;

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer,
};

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: "eu-west-1_BSw1uKjrC",
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: "1objh42gfqdiprbe6nfb2u5fbf",
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: "eu-west-1:429c297e-d491-4914-87dc-0b2a7bc3c1b5",
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      // allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: "https://foqus-portal.auth.eu-west-1.amazoncognito.com",
          scopes: [
            "phone",
            "email",
            "profile",
            "openid",
            "custom:DashboardId",
            "custom:PlaceId",
            "custom:group",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: ["http://localhost:3000/dashboard"],
          redirectSignOut: ["http://localhost:3000/login"],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
});

function App() {
  const { user } = useAuthenticator();

  const [collapsed, setCollapsed] = useState(false);
  // const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  // const [dashboardData, setDashboardData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isDarkTheme = useUnit($isDarkTheme);
  const getDuplicatesPending = useUnit(getDuplicatesFx.pending);
  const getSearchByIdDataPending = useUnit(getSearchByIdDataFx.pending);
  const disassociateUserPending = useUnit(disassociateUserFx.pending);
  const getBadImagesPending = useUnit(getBadImagesFx.pending);

  const loading =
    getDuplicatesPending ||
    getSearchByIdDataPending ||
    disassociateUserPending ||
    getBadImagesPending;

  // const setUserGroups = useUnit(setUserGroupsEv);
  const setUserKeys = useUnit(setUserKeysEv);
  const setUserAttributes = useUnit(setUserAttributesEv);
  // const userGroups = useUnit($userGroups);

  // UPDATE IS HERE
  const [userGroups, setUserGroups] = useState([]);
  const [isUserGroupsLoaded, setIsUserGroupsLoaded] = useState(false);
  console.log("✅ userGroups in App.js:", userGroups);

  // Effect to monitor userGroups
  useEffect(() => {
    if (userGroups && userGroups.length > 0) {
      console.log("✅ userGroups are fully loaded:", userGroups);
      setIsUserGroupsLoaded(true);
    }
  }, [userGroups]);

  // console.log('im at App.js')
  inactivityHandler();

  function useInitializeUserGroups(user) {
    useEffect(() => {
      const initializeUserGroups = async () => {
        try {
          if (user) {
            const session = await fetchAuthSession();
            const userAttributes = await fetchUserAttributes();
            //fixme comment s3
            try {
              const credentials = session.credentials;
              const { accessKeyId, secretAccessKey, sessionToken } =
                credentials;

              // Создаем экземпляр клиента S3 с временными креденшелами
              const s3 = new S3Client({
                region: "eu-west-1", // Укажи свой регион
                credentials: {
                  accessKeyId,
                  secretAccessKey,
                  sessionToken,
                },
              });

              // Выполняем запрос на список объектов в S3
              const command = new ListObjectsV2Command({
                Bucket: "test-lab-bucket-s3", // Укажи имя твоего бакета
              });

              const data = await s3.send(command);
              console.log("S3 Objects:", data);
            } catch (error) {
              console.error("Error accessing S3:", error);
            }
            const group = session.tokens.accessToken.payload["cognito:groups"];
            setUserGroups(group || []);
            setUserAttributes(userAttributes);
            console.log("session: ", session);
            // const keys = await getAWSCredentials()
            // setUserKeys(keys.credentials.credentials)
          }
        } catch (error) {
          console.error("Error fetching user groups:", error);
          setUserGroups([]);
        }
      };

      initializeUserGroups();
    }, [user, setUserGroups]);
  }

  useInitializeUserGroups(user);

  const onMenuClick = (e) => {
    if (e.key !== "signout") {
      navigate(e.key);
    } else {
      navigate("/");
      signOut();
    }
  };
  const myWidth = 230;

  useEffect(() => {
    getPlaceIdsFx();
  }, []);

  const adminStaffItems = [
    {
      key: "testing",
      icon: <TableOutlined />,
      label: "Testing",
    },
    {
      key: "testing-client",
      icon: <TableOutlined />,
      label: "Testing client",
    },
    {
      key: "unrecognized-testing",
      icon: <TableOutlined />,
      label: "Unrecognized testing",
    },
    {
      key: "search-by-id",
      icon: <TableOutlined />,
      label: "Search by ID",
    },
    {
      key: "duplicates",
      icon: <TableOutlined />,
      label: "Duplicates",
    },
  ];
  const listsItems = [
    userGroups?.includes("portal_admin")
      ? {
          key: "user-list",
          icon: <UserOutlined />,
          label: "User list",
        }
      : null,
    userGroups?.includes("portal_admin") ||
    (userGroups?.includes("Customer") &&
      (userGroups?.includes("Amanat") || userGroups?.includes("Kolibrix")))
      ? {
          key: "client-user-list",
          icon: <UserOutlined />,
          label: "Список сотрудников",
        }
      : null,

    userGroups?.includes("portal_admin") ||
    (userGroups?.includes("Customer") && userGroups?.includes("Amanat"))
      ? {
          key: "client-user-list-refactor",
          icon: <UserOutlined />,
          label: "Сотрудники Рестайлинг",
        }
      : null,

    userGroups?.includes("portal_admin") ||
    (userGroups?.includes("Customer") &&
      userGroups?.includes("Amanat") &&
      !userGroups?.includes("CustomerOnlyView"))
      ? {
          key: "client-user-list-potential",
          icon: <UserOutlined />,
          label: "Потенциальные Сотрудники",
        }
      : null,

    userGroups?.includes("portal_admin") ||
    (userGroups?.includes("Customer") && userGroups?.includes("Aubakirova")) ||
    (userGroups?.includes("Customer") && userGroups?.includes("Pifagor"))
      ? {
          key: "user-list-edu-restyle",
          icon: <UserOutlined />,
          label: "Ученики",
        }
      : null,
    userGroups?.includes("portal_admin") ||
    (userGroups?.includes("Customer") && userGroups?.includes("Aubakirova"))
      ? {
          key: "school-staff-list",
          icon: <UserOutlined />,
          label: "Сотрудники",
        }
      : null,
    userGroups?.includes("portal_admin") ||
    (userGroups?.includes("Customer") && userGroups?.includes("Aubakirova"))
      ? {
          key: "user-list-edu",
          icon: <UserOutlined />,
          label: "Потенциальные",
        }
      : null,
    userGroups?.includes("portal_admin") ||
    (userGroups?.includes("Customer") && userGroups?.includes("BlackList"))
      ? {
          key: "black-list",
          icon: <UserOutlined />,
          label: "Black List",
        }
      : null,
  ];

  const generateMenuItems = () => {
    let result = [
      {
        key: "dashboard",
        icon: <PieChartOutlined />,
        label: "Dashboards",
      },
    ];
    if (
      userGroups?.includes("foqus_portal_demo") ||
      userGroups?.includes("portal_admin")
    ) {
      result.push({
        key: "quicksight-q",
        icon: <PieChartOutlined />,
        label: "Your AI Assistant",
      });
      result.push({
        key: "hr-event",
        icon: <PieChartOutlined />,
        label: "HR Event",
      });
      result.push({
        key: "demo-dashboard",
        icon: <PieChartOutlined />,
        label: "Demo Dashboards",
      });
      result.push({
        key: "demo-video",
        icon: <VideoCameraOutlined />,
        label: "DemoVideo",
      });
    }

    if (userGroups?.includes("portal_admin")) {
      result.push({
        key: "lists",
        icon: <UserOutlined />,
        label: "Списки",
        children: listsItems,
      });
    } else {
      result.push(...listsItems);
    }

    if (userGroups?.includes("portal_admin")) {
      result.push({
        key: "admin-staff",
        icon: <TableOutlined />,
        label: "Admin Staff",
        children: adminStaffItems,
      });
      result.push({
        key: "bad-images",
        icon: <UserOutlined />,
        label: "Bad Images",
      });
      result.push({
        key: "portal-users",
        icon: <UserOutlined />,
        label: "Portal Users",
      });
    }
    return result;
  };

  return user ? (
    <ConfigProvider theme={main_theme_config}>
      <Layout
        style={{
          minHeight: "100svh",
        }}
        hasSider
      >
        {!location.pathname.includes("registration") ? (
          <Sider
            width={myWidth}
            className={"sider"}
            // style={{
            //   width: '500px !important'
            // }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            collapsedWidth={74}
          >
            <div
              style={{
                padding: "15px",
              }}
            >
              {collapsed ? (
                <img src={logo_mini_foqus} />
              ) : (
                <img src={logo_max_foqus} />
              )}
            </div>

            <Menu
              style={{ color: "#ffffff" }}
              theme="dark"
              // defaultSelectedKeys={['dashboard']}
              mode="inline"
              onClick={onMenuClick}
              selectedKeys={
                window.location.pathname.split("/")[1].length > 0
                  ? window.location.pathname.split("/").slice(1)
                  : ["demo-dashboard"]
              }
              items={generateMenuItems()}
            />

            <div
              key="signout"
              style={{
                color: "#ffffff",
              }}
              onClick={signOut}
              className={"custom_menu_item"}
            >
              <LogoutOutlined />
              <span>Sign Out</span>
            </div>
            {/*</div>*/}
          </Sider>
        ) : (
          ""
        )}
        <Layout
          style={{
            backgroundColor: isDarkTheme ? "#232323" : "#FFFFFF",
            marginInlineStart: collapsed ? 74 : 230,
            transition: "0.5s all",
          }}
        >
          <Spin spinning={loading} className={"main_spinner"}>
            <Content
              style={{
                margin: "16px 16px",
              }}
            >
              {/*<Heading level={1}>Hello, {userName}</Heading>*/}
              <Outlet context={{ userGroups, isUserGroupsLoaded }} />
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </ConfigProvider>
  ) : (
    <Login />
  );
}

export default App;
