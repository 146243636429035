import { Button, Form, Input, Modal, Select } from "antd";
import { useUnit } from "effector-react";
import {
  $chosenUser,
  changeChosenUserEv,
  sendEditUserFx,
} from "../../models/userListModel";
import { useEffect } from "react";
import style from "./ClientUserList.module.css";

const selectOptions = [
  {
    label: "Сотрудник",
    value: "Сотрудник",
  },
  {
    label: "Уволен",
    value: "Уволен",
  },
  {
    label: "Потенциальный сотрудник",
    value: "Потенциальный сотрудник",
  },
];

export default function UserListModal() {
  const [form] = Form.useForm();
  const chosenUser = useUnit($chosenUser);
  const changeChosenUser = useUnit(changeChosenUserEv);
  const sendEditUser = useUnit(sendEditUserFx);
  const pending = useUnit(sendEditUserFx.pending);

  const onFinish = (values) => {
    debugger;
    const payload = {
      ...chosenUser,
      // emp_department: values.new_department,
      // emp_position: values.new_position,
      // emp_category: values.new_category,
      start_work_time: values.new_start_work_time,
      end_work_time: values.new_end_work_time,
    };
    sendEditUser(payload);
  };

  const onClose = () => {
    changeChosenUser(null);
  };

  useEffect(() => {
    if (chosenUser)
      form.setFieldsValue({
        // new_department: chosenUser?.emp_department,
        // new_position: chosenUser?.emp_position,
        // new_category: chosenUser?.emp_category,
        new_start_work_time: chosenUser?.start_work_time,
        new_end_work_time: chosenUser?.end_work_time,
      });
  }, [chosenUser]);

  return (
    <Modal
      title={"Изменить сотрудника"}
      open={!!chosenUser}
      onCancel={onClose}
      confirmLoading={pending}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        style={{
          marginTop: "24px",
        }}
      >
        <Form.Item name={"new_start_work_time"} label={"Начало рабочего дня: "}>
          <Input />
        </Form.Item>
        <Form.Item name={"new_end_work_time"} label={"Конец рабочего дня: "}>
          <Input />
        </Form.Item>
        {/* <Form.Item name={"new_department"} label={"Департамент"}>
          <Input />
        </Form.Item>
        <Form.Item name={"new_position"} label={"Должность"}>
          <Input />
        </Form.Item>
        <Form.Item name={"new_category"} label={"Статус"}>
          <Select options={selectOptions} />
        </Form.Item> */}
      </Form>
      <Button
        onClick={form.submit}
        className={style.modal_button}
        type={"primary"}
        style={{ marginRight: "8px" }}
      >
        Изменить
      </Button>
      <Button onClick={onClose} className={style.modal_button}>
        Отмена
      </Button>
    </Modal>
  );
}
