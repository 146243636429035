import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Select,
  Modal,
  Form,
  message,
  Table,
  Segmented,
  DatePicker,
} from "antd";
import {
  EditOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import style from "./SchoolUserList.module.css";
import {
  $userListEdu,
  changeShowCreateModalEv,
  getUserListEduFx,
  $userEmpCategory,
  changeUserEmpCategoryEv,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Spin } from "antd";

dayjs.extend(customParseFormat);

const { Search } = Input;
const { Option } = Select;

// Custom Image component for rendering presigned URLs
const ImageFromS3 = ({ presigned_url }) => {
  return (
    <img
      src={presigned_url}
      alt="Student"
      style={{
        width: 60,
        height: 60,
        borderRadius: "50%",
        objectFit: "cover",
        backgroundColor: "#f0f0f0", // Fallback background
      }}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite error loop
        e.target.src = "/default_student_image.png"; // Fallback image
      }}
    />
  );
};

// Grade and Letter Options Based on User Group
const GRADE_OPTIONS = {
  Aubakirova: Array.from({ length: 11 }, (_, i) => i + 1), // 1-11
  Pifagor: Array.from({ length: 7 }, (_, i) => i + 5), // 5-11
};

const LETTER_OPTIONS = {
  Aubakirova: ["А", "Б", "В", "Г"],
  Pifagor: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"],
};

function SchoolUserListRestyle({ userGroups = [] }) {
  console.log("✅ userGroups prop:", userGroups); // Add this here

  const [form] = Form.useForm();
  const studentList = useUnit($userListEdu);
  const loading = useUnit(getUserListEduFx.pending);
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const userEmpCategory = useUnit($userEmpCategory);
  const changeUserEmpCategory = useUnit(changeUserEmpCategoryEv);

  const [layout, setLayout] = useState("cards");
  const [gradeFilter, setGradeFilter] = useState(null);
  const [dobRange, setDobRange] = useState([null, null]);
  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [gradeNumberFilter, setGradeNumberFilter] = useState(null);
  const [gradeLetterFilter, setGradeLetterFilter] = useState(null);
  // State for uploaded photo
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [uploadedPhotoBase64, setUploadedPhotoBase64] = useState(null);

  const [combinedStudentList, setCombinedStudentList] = useState([]);

  // Dynamic dropdown values
  const gradeNumberOptions = userGroups.includes("Pifagor")
    ? GRADE_OPTIONS.Pifagor
    : GRADE_OPTIONS.Aubakirova;

  const gradeLetterOptions = userGroups.includes("Pifagor")
    ? LETTER_OPTIONS.Pifagor
    : LETTER_OPTIONS.Aubakirova;

  const showGenderField = userGroups.includes("Aubakirova");

  const genderOptions = [
    { label: "Мужчина", value: "Male" },
    { label: "Женщина", value: "Female" },
  ];

  const translateGender = (gender) => {
    const option = genderOptions.find((opt) => opt.value === gender);
    return option ? option.label : "Не указан"; // Default to "Не указан" if invalid
  };

  const handlePhotoUpload = ({ fileList }) => {
    // Check if a file exists in the list
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj || fileList[0];

      // Use FileReader to convert to Base64
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedPhotoBase64(e.target.result);
        message.success("Фотография успешно загружена!");
      };
      reader.readAsDataURL(file);
    } else {
      setUploadedPhotoBase64(null); // Clear photo if deleted
      message.info("Фотография удалена.");
    }
  };

  const [pagination, setPagination] = useState({
    current: 1, // Current page number
    pageSize: 10, // Rows per page
  });

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split(".");
    return new Date(`${year}-${month}-${day}`);
  };

  // State for Add Student Modal
  const [isAddStudentModalVisible, setIsAddStudentModalVisible] =
    useState(false);

  const handleAddStudent = async (values) => {
    // Validate the uploaded photo
    if (!uploadedPhotoBase64) {
      message.error(
        "Пожалуйста, загрузите фотографию перед добавлением ученика."
      );
      return;
    }

    const formattedDateOfBirth = values.date_of_birth
      ? dayjs(values.date_of_birth).format("DD.MM.YYYY")
      : "";

    // 🛠️ Determine `place_id` dynamically
    let place_id = "zasa05e1p1"; // Default for Aubakirova

    if (userGroups?.includes("Pifagor")) {
      if (values.grade_number >= 5 && values.grade_number <= 6) {
        place_id = "pmsa10h3p6";
      } else if (values.grade_number >= 7 && values.grade_number <= 11) {
        place_id = "phsa10m6f1";
      } else {
        message.error("Некорректный номер класса для Pifagor.");
        return;
      }
    }

    const requestBody = {
      place_id,
      properties: {
        date_of_birth: formattedDateOfBirth,
        emp_category: "Ученик",
        user_email: values.user_email,
        grade: `${values.grade_number}${values.grade_letter}`,
        grade_number: values.grade_number,
        grade_letter: values.grade_letter,
        created_type: "manual",
        checkin_status: "onboarded",
        user_gender: values.user_gender,
      },
      user_name: values.user_name,
      user_surname: values.user_surname,
      data_url: [uploadedPhotoBase64], // Include Base64 photo in an array
    };

    console.log("📤 Sending API Request with Payload:", requestBody);

    try {
      const response = await fetch(
        "https://ste1h7g7s7.execute-api.eu-west-1.amazonaws.com/LoyaltySystem1/UserRecords",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (response.ok) {
        message.success("Новый ученик успешно добавлен!");
        console.log(data);
      } else {
        message.error(`Ошибка добавления: ${data.body}`);
      }
    } catch (error) {
      message.error(`Ошибка подключения: ${error.message}`);
    }

    setIsAddStudentModalVisible(false);
    setUploadedPhotoBase64(null); // Reset photo state
  };

  // useEffect(() => {
  //   if (userGroups?.includes("Aubakirova")) {
  //     getUserListEduFx({ sort_key: "zasa05e1p1", emp_category: "Ученик" });
  //   } else if (userGroups?.includes("Pifagor")) {
  //     ["phsa10m6f1", "pmsa10h3p6"].forEach((key) => {
  //       getUserListEduFx({ sort_key: key, emp_category: "Ученик" });
  //     });
  //   }
  // }, [userGroups]);

  useEffect(() => {
    const fetchStudents = async () => {
      if (userGroups?.includes("Aubakirova")) {
        await getUserListEduFx({
          sort_key: "zasa05e1p1",
          emp_category: "Ученик",
        });
      } else if (userGroups?.includes("Pifagor")) {
        const sortKeys = ["phsa10m6f1", "pmsa10h3p6"];
        try {
          const responses = await Promise.all(
            sortKeys.map((key) =>
              getUserListEduFx({ sort_key: key, emp_category: "Ученик" })
            )
          );

          // Combine results into one array
          const combinedResults = responses.flat();
          console.log("✅ Combined student list:", combinedResults);

          // Manually update your store or set a local state
          setCombinedStudentList(combinedResults);
        } catch (error) {
          console.error(
            "❌ Error fetching students for multiple sort_keys:",
            error
          );
        }
      }
    };

    fetchStudents();
  }, [userGroups]);

  // When editingRecord changes, reset the form with the new values
  useEffect(() => {
    if (editingRecord) {
      form.setFieldsValue({
        user_name: editingRecord.user_name || "",
        user_surname: editingRecord.user_surname || "",
        grade_number: editingRecord.grade
          ? extractGradeNumber(editingRecord.grade)
          : "",
        grade_letter: editingRecord.grade
          ? extractGradeLetter(editingRecord.grade)
          : "",
        date_of_birth: editingRecord.date_of_birth
          ? dayjs(editingRecord.date_of_birth, "DD.MM.YYYY")
          : null, // Parse date correctly
        user_email: editingRecord.user_email || "",
        user_gender: editingRecord.user_gender || "",
      });
    }
  }, [editingRecord, form]);

  // Helper Functions
  const extractGradeNumber = (grade) => {
    const match = grade?.match(/^(\d+)/); // Extract digits at the beginning
    return match ? parseInt(match[1], 10) : "";
  };

  const extractGradeLetter = (grade) => {
    const match = grade?.match(/(\D)$/); // Extract the last non-digit character
    return match ? match[1] : "";
  };

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const filteredStudents = useMemo(() => {
    const sourceList =
      userGroups?.includes("Pifagor") && combinedStudentList.length > 0
        ? combinedStudentList
        : studentList;

    if (!Array.isArray(sourceList)) {
      console.warn("⚠️ studentList is not an array:", sourceList);
      return [];
    }

    console.log("✅ Student List for Filtering:", sourceList);

    return sourceList
      .filter((student) => {
        if (!student || student.emp_category !== "Ученик") return false;

        const matchesSortKey = (() => {
          if (userGroups?.includes("Aubakirova")) {
            return student.sort_key === "zasa05e1p1";
          } else if (userGroups?.includes("Pifagor")) {
            return (
              student.sort_key === "phsa10m6f1" ||
              student.sort_key === "pmsa10h3p6"
            );
          }
          return true;
        })();

        const matchesSearch = Object.values(student)
          .join(" ")
          .toLowerCase()
          .includes(searchText.toLowerCase());

        const matchesGradeNumber =
          gradeNumberFilter?.length > 0
            ? gradeNumberFilter.includes(parseInt(student.grade_number, 10))
            : true;

        const matchesGradeLetter =
          gradeLetterFilter?.length > 0
            ? gradeLetterFilter.includes(student.grade_letter)
            : true;

        const matchesDob =
          dobRange[0] && dobRange[1]
            ? new Date(student.date_of_birth) >= new Date(dobRange[0]) &&
              new Date(student.date_of_birth) <= new Date(dobRange[1])
            : true;

        return (
          matchesSortKey &&
          matchesSearch &&
          matchesGradeNumber &&
          matchesGradeLetter &&
          matchesDob
        );
      })
      .sort((a, b) => {
        if (sortOption === "name-asc") {
          return a.user_name.localeCompare(b.user_name, "ru");
        } else if (sortOption === "name-desc") {
          return b.user_name.localeCompare(a.user_name, "ru");
        } else if (sortOption === "grade-asc") {
          return (
            a.grade_number - b.grade_number ||
            a.grade_letter.localeCompare(b.grade_letter)
          );
        } else if (sortOption === "grade-desc") {
          return (
            b.grade_number - a.grade_number ||
            b.grade_letter.localeCompare(a.grade_letter)
          );
        } else if (sortOption === "dob-asc") {
          return (
            new Date(parseDate(a.date_of_birth)).getTime() -
            new Date(parseDate(b.date_of_birth)).getTime()
          );
        } else if (sortOption === "dob-desc") {
          return (
            new Date(parseDate(b.date_of_birth)).getTime() -
            new Date(parseDate(a.date_of_birth)).getTime()
          );
        }
        return 0;
      });
  }, [
    studentList,
    combinedStudentList,
    searchText,
    gradeNumberFilter,
    gradeLetterFilter,
    dobRange,
    sortOption,
    userGroups,
  ]);

  const handleEdit = (record) => {
    setEditingRecord(record);
    setIsEditModalVisible(true);
  };
  const handleModalClose = () => {
    setIsEditModalVisible(false);
    setEditingRecord(null);
    setIsAddStudentModalVisible(false); // Close the modal
    setUploadedPhotoBase64(null); // Clear uploaded photo state
    form.resetFields(); // Ensures all fields, including `date_of_birth`, are cleared
  };

  const handleSaveEdit = async (values) => {
    const {
      user_name,
      user_surname,
      grade_number,
      grade_letter,
      user_email,
      date_of_birth,
    } = values;

    const formattedDateOfBirth = values.date_of_birth
      ? dayjs(values.date_of_birth).format("DD.MM.YYYY")
      : "";

    // Determine place_id based on grade_number
    let place_id = editingRecord?.sort_key; // Default to the current sort_key

    if (userGroups?.includes("Pifagor")) {
      if (grade_number >= 5 && grade_number <= 6) {
        place_id = "pmsa10h3p6";
      } else if (grade_number >= 7 && grade_number <= 11) {
        place_id = "phsa10m6f1";
      }
    } else if (userGroups?.includes("Aubakirova")) {
      place_id = "zasa05e1p1"; // Static for Aubakirova
    }

    const requestBody = {
      type: "student",
      partition_key: editingRecord?.partition_key,
      sort_key: place_id, // Updated place_id if grade_number changes
      data: {
        user_name,
        user_surname,
        grade_number,
        grade_letter,
        grade: `${grade_number}${grade_letter}`, // Combine number and letter
        user_email,
        date_of_birth: formattedDateOfBirth,
        user_gender: values.user_gender,
        // user_gender,
      },
    };

    console.log("📤 Sending API Request with Payload:", requestBody);

    try {
      const response = await fetch(
        "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/ChangeEDUInfo2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const rawResponse = await response.text();
      console.log("📥 Raw API Response:", rawResponse);

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status} - ${rawResponse}`);
      }

      const data = rawResponse ? JSON.parse(rawResponse) : {};
      console.log("✅ Parsed API Response:", data);

      if (data?.statusCode === 200) {
        message.success("Данные ученика успешно обновлены!");
      } else {
        throw new Error(data?.message || "Неизвестная ошибка на сервере");
      }

      setIsEditModalVisible(false);
      getUserListEduFx(); // Refresh the student list
    } catch (error) {
      message.error(`Ошибка: ${error.message}`);
      console.error("❌ Error in API call:", error);
    }
  };

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title}>Список учеников</div>
      {/* Top Controls */}
      <div className={style.user_list_top_wrapper}>
        {/* <Segmented
          options={[{ label: "Ученик", value: "Ученик" }]}
          value={userEmpCategory}
          onChange={changeUserEmpCategory}
        /> */}
        <Search
          placeholder="Поиск по любому параметру"
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 200 }}
        />
        <Select
          placeholder="Сортировка"
          onChange={setSortOption}
          style={{ width: 200 }}
        >
          <Option value="name-asc">Имя: А-Я</Option>
          <Option value="name-desc">Имя: Я-А</Option>
          <Option value="grade-asc">Класс: по возрастанию</Option>
          <Option value="grade-desc">Класс: по убыванию</Option>
          <Option value="dob-asc">Дата рождения: старшие</Option>
          <Option value="dob-desc">Дата рождения: младшие</Option>
        </Select>
        <Select
          mode="multiple"
          placeholder="Фильтр по номеру класса"
          onChange={setGradeNumberFilter}
          allowClear
          style={{ width: 150 }}
        >
          {gradeNumberOptions.map((number) => (
            <Option key={number} value={number}>
              {number}
            </Option>
          ))}
        </Select>

        <Select
          mode="multiple"
          placeholder="Фильтр по букве класса"
          onChange={setGradeLetterFilter}
          allowClear
          style={{ width: 150 }}
        >
          {gradeLetterOptions.map((letter) => (
            <Option key={letter} value={letter}>
              {letter}
            </Option>
          ))}
        </Select>

        <Button
          onClick={() => setLayout("cards")}
          icon={<AppstoreOutlined />}
          type={layout === "cards" ? "primary" : "default"}
        />
        <Button
          onClick={() => setLayout("list")}
          icon={<UnorderedListOutlined />}
          type={layout === "list" ? "primary" : "default"}
        />
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => setIsAddStudentModalVisible(true)} // Toggle the modal visibility
        >
          Добавить ученика
        </Button>
      </div>
      {/* List or Card View */}
      {layout === "cards" ? (
        <div
          className={style.cards_wrapper}
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          {loading ? (
            // Show loading spinner
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Spin tip="Загрузка списка учеников..." size="large" />
            </div>
          ) : filteredStudents.length > 0 ? (
            // Render student cards
            filteredStudents.map((student) => (
              <div
                key={student.id}
                style={{
                  position: "relative",
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  width: "45%",
                  minWidth: "350px",
                  display: "flex",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  backgroundColor: "#fff",
                }}
              >
                {/* Edit Button */}
                <div
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => handleEdit(student)}
                    style={{ backgroundColor: "#007bff", color: "white" }}
                  />
                </div>

                {/* Photo Section */}
                <div style={{ padding: "15px" }}>
                  <img
                    src={student.presigned_url || "/default_student_image.png"}
                    alt="Student"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "3px solid #007bff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      Modal.info({
                        title: "Просмотр изображения",
                        content: (
                          <img
                            src={
                              student.presigned_url ||
                              "/default_student_image.png"
                            }
                            alt="Enlarged Student"
                            style={{ width: "100%" }}
                          />
                        ),
                        icon: null,
                      });
                    }}
                  />
                </div>

                {/* Details Section */}
                <div style={{ flex: "1", padding: "15px" }}>
                  <h3
                    style={{
                      margin: "0 0 10px",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {`${student.user_name} ${student.user_surname}`}
                  </h3>
                  <div style={{ marginBottom: "8px" }}>
                    <b>Класс:</b> {student.grade_number}
                    {student.grade_letter}
                  </div>
                  {userGroups.includes("Aubakirova") && (
                    <div style={{ marginBottom: "8px" }}>
                      <b>Пол:</b> {translateGender(student.user_gender)}
                    </div>
                  )}

                  <div style={{ marginBottom: "8px" }}>
                    <b>Дата рождения:</b> {student.date_of_birth}
                  </div>
                  <div>
                    <b>Email:</b> {student.user_email}
                  </div>
                </div>
              </div>
            ))
          ) : (
            // Display a message if no students are found
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              Нет данных для отображения.
            </div>
          )}
        </div>
      ) : (
        <Table
          dataSource={filteredStudents}
          rowKey="id"
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
          }}
          onChange={handleTableChange}
          columns={[
            {
              title: "№",
              key: "index",
              render: (text, record, index) =>
                (pagination.current - 1) * pagination.pageSize + index + 1,
              width: 50,
            },
            { title: "Имя", dataIndex: "user_name", key: "user_name" },
            {
              title: "Фамилия",
              dataIndex: "user_surname",
              key: "user_surname",
            },
            {
              title: "Класс",
              key: "grade",
              render: (text, record) => {
                return record.grade_number && record.grade_letter
                  ? `${record.grade_number}${record.grade_letter}`
                  : record.grade || "Не указан";
              },
            },
            // Conditionally add gender column
            showGenderField && {
              title: "Пол",
              key: "user_gender",
              render: (text, record) => translateGender(record.user_gender),
            },
            {
              title: "Дата рождения",
              dataIndex: "date_of_birth",
              key: "date_of_birth",
            },
            { title: "Email", dataIndex: "user_email", key: "user_email" },
            {
              title: "Действия",
              key: "actions",
              render: (record) => (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(record)}
                >
                  Редактировать
                </Button>
              ),
            },
          ]}
        />
      )}

      {/* Edit Modal */}
      <Modal
        title="Редактирование ученика"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form
          form={form} // Attach the form instance
          layout="vertical"
          onFinish={handleSaveEdit}
        >
          <Form.Item label="Имя" name="user_name">
            <Input />
          </Form.Item>

          <Form.Item label="Фамилия" name="user_surname">
            <Input />
          </Form.Item>

          {/* Show gender only for Aubakirova */}
          {showGenderField && (
            <Form.Item
              label="Пол"
              name="user_gender"
              rules={[{ required: true, message: "Пожалуйста, выберите пол" }]}
            >
              <Select placeholder="Выберите пол">
                <Option value="Male">Мужчина</Option>
                <Option value="Female">Женщина</Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Номер класса"
            name="grade_number"
            rules={[
              { required: true, message: "Пожалуйста, выберите номер класса" },
            ]}
          >
            <Select placeholder="Выберите номер класса">
              {gradeNumberOptions.map((number) => (
                <Select.Option key={number} value={number}>
                  {number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Буква класса"
            name="grade_letter"
            rules={[
              { required: true, message: "Пожалуйста, выберите букву класса" },
            ]}
          >
            <Select placeholder="Выберите букву класса">
              {gradeLetterOptions.map((letter) => (
                <Select.Option key={letter} value={letter}>
                  {letter}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Дата рождения"
            name="date_of_birth"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите или введите дату рождения",
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      "Пожалуйста, выберите или введите дату."
                    );
                  }
                  if (!dayjs(value, "DD.MM.YYYY", true).isValid()) {
                    return Promise.reject(
                      "Некорректный формат даты (DD.MM.YYYY)."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              format="DD.MM.YYYY"
              placeholder="Введите или выберите дату"
              style={{ width: "100%" }}
              onChange={(date) => form.setFieldsValue({ date_of_birth: date })}
              onBlur={(e) => {
                const enteredDate = e.target.value;
                if (dayjs(enteredDate, "DD.MM.YYYY", true).isValid()) {
                  form.setFieldsValue({
                    date_of_birth: dayjs(enteredDate, "DD.MM.YYYY"),
                  });
                } else if (enteredDate) {
                  message.error(
                    "Некорректный формат даты. Используйте DD.MM.YYYY"
                  );
                }
              }}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>

          <Form.Item label="Email" name="user_email">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* Add Student Modal */}
      <Modal
        title="Добавить нового ученика"
        visible={isAddStudentModalVisible}
        onCancel={handleModalClose} // Ensure this uses handleModalClose
        footer={null}
        destroyOnClose={true} // Ensures modal resets when closed
      >
        <Form layout="vertical" onFinish={handleAddStudent}>
          <Form.Item label="Фотография ученика">
            <Upload
              name="photo"
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Prevent auto-upload
              onChange={handlePhotoUpload}
              accept="image/*"
            >
              <Button icon={<UploadOutlined />}>Загрузить фото</Button>
            </Upload>
            {uploadedPhotoBase64 && (
              <div style={{ marginTop: 10 }}>
                <img
                  src={uploadedPhotoBase64}
                  alt="Preview"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
            )}
          </Form.Item>

          <Form.Item
            label="Имя"
            name="user_name"
            rules={[{ required: true, message: "Введите имя" }]}
          >
            <Input placeholder="Имя" />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="user_surname"
            rules={[{ required: true, message: "Введите фамилию" }]}
          >
            <Input placeholder="Фамилия" />
          </Form.Item>

          {/* Show gender only for Aubakirova */}
          {showGenderField && (
            <Form.Item
              label="Пол"
              name="user_gender"
              rules={[{ required: true, message: "Пожалуйста, выберите пол" }]}
            >
              <Select placeholder="Выберите пол">
                <Option value="Male">Мужчина</Option>
                <Option value="Female">Женщина</Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Номер класса"
            name="grade_number"
            rules={[
              { required: true, message: "Пожалуйста, выберите номер класса" },
            ]}
          >
            <Select placeholder="Выберите номер класса">
              {gradeNumberOptions.map((number) => (
                <Select.Option key={number} value={number}>
                  {number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Буква класса"
            name="grade_letter"
            rules={[
              { required: true, message: "Пожалуйста, выберите букву класса" },
            ]}
          >
            <Select placeholder="Выберите букву класса">
              {gradeLetterOptions.map((letter) => (
                <Select.Option key={letter} value={letter}>
                  {letter}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Дата рождения"
            name="date_of_birth"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите или введите дату рождения",
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      "Пожалуйста, выберите или введите дату."
                    );
                  }
                  if (!dayjs(value, "DD.MM.YYYY", true).isValid()) {
                    return Promise.reject(
                      "Некорректный формат даты (DD.MM.YYYY)."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              format="DD.MM.YYYY"
              placeholder="Введите или выберите дату"
              style={{ width: "100%" }}
              onChange={(date) => form.setFieldsValue({ date_of_birth: date })}
              onBlur={(e) => {
                const enteredDate = e.target.value;
                if (dayjs(enteredDate, "DD.MM.YYYY", true).isValid()) {
                  form.setFieldsValue({
                    date_of_birth: dayjs(enteredDate, "DD.MM.YYYY"),
                  });
                } else if (enteredDate) {
                  message.error(
                    "Некорректный формат даты. Используйте DD.MM.YYYY"
                  );
                }
              }}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="user_email"
            rules={[{ required: true, message: "Введите email" }]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить ученика
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default SchoolUserListRestyle;
