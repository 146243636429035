import { Button, Form, Input, Modal, Select } from "antd";
import { useUnit } from "effector-react";
import {
  $chosenUser,
  changeChosenUserEv,
  sendEditUserFx,
} from "../../models/userListModel";
import { useEffect } from "react";
import style from "./ClientUserList.module.css";

const { Option } = Select;
const selectOptions = [
  {
    label: "Сотрудник",
    value: "Сотрудник",
  },
  {
    label: "Уволен",
    value: "Уволен",
  },
  {
    label: "Потенциальный сотрудник",
    value: "Потенциальный сотрудник",
  },
];

export default function UserListModal() {
  const [form] = Form.useForm();
  const chosenUser = useUnit($chosenUser);
  const changeChosenUser = useUnit(changeChosenUserEv);
  const sendEditUser = useUnit(sendEditUserFx);
  const pending = useUnit(sendEditUserFx.pending);

  const onFinish = (values) => {
    debugger;
    const payload = {
      ...chosenUser,
      user_name: values.user_name,
      user_surname: values.user_surname,
      user_gender: values.user_gender,
      emp_department: values.new_department,
      emp_position: values.new_position,
      emp_category: values.new_category,
      start_work_time: values.start_work_time,
      end_work_time: values.end_work_time,
    };
    sendEditUser(payload);
  };

  const onClose = () => {
    changeChosenUser(null);
  };

  useEffect(() => {
    if (chosenUser)
      form.setFieldsValue({
        user_name: chosenUser?.user_name,
        user_surname: chosenUser?.user_surname,
        user_gender: chosenUser?.user_gender,
        emp_department: chosenUser?.emp_department,
        emp_position: chosenUser?.emp_position,
        emp_category: chosenUser?.emp_category,
        start_work_time: chosenUser?.start_work_time,
        end_work_time: chosenUser?.end_work_time,
      });
  }, [chosenUser]);

  return (
    <Modal
      title={"Изменить данные сотрудника"}
      open={!!chosenUser}
      onCancel={onClose}
      confirmLoading={pending}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        style={{
          marginTop: "24px",
        }}
      >
        <Form.Item name={"user_name"} label={"Имя"}>
          <Input />
        </Form.Item>
        <Form.Item name={"user_surname"} label={"Фамилия"}>
          <Input />
        </Form.Item>
        <Form.Item label="Пол" name="user_gender">
          <Select placeholder="Выберите пол">
            <Option value="Male">М</Option>
            <Option value="Female">Ж</Option>
          </Select>
        </Form.Item>
        <Form.Item name={"emp_department"} label={"Департамент"}>
          <Input />
        </Form.Item>
        <Form.Item name={"emp_position"} label={"Должность"}>
          <Input />
        </Form.Item>
        <Form.Item name={"emp_category"} label={"Статус"}>
          <Select options={selectOptions} />
        </Form.Item>
        <Form.Item name={"start_work_time"} label={"Начало рабочего дня: "}>
          <Input />
        </Form.Item>
        <Form.Item name={"end_work_time"} label={"Конец рабочего дня: "}>
          <Input />
        </Form.Item>
      </Form>
      <Button
        onClick={form.submit}
        className={style.modal_button}
        type={"primary"}
        style={{ marginRight: "8px" }}
      >
        Изменить
      </Button>
      <Button onClick={onClose} className={style.modal_button}>
        Отмена
      </Button>
    </Modal>
  );
}
