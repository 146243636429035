import { createEffect } from "effector";
import { notification } from "antd";

export const getUserListFx = createEffect().use(async (payload) => {
  try {
    console.log("getUserListFx called with payload:", payload);

    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/prod-user-list-api",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sort_key: payload ? payload : "amaa25d7c9",
          emp_category: "Сотрудник",
        }),
      }
    );

    console.log("API Response Status:", response.status);
    let result = await response.json();
    console.log("API Response Body:", result);

    const tempRes = JSON.parse(result.body);
    console.log("getUserListFx:: Parsed body", tempRes);

    return tempRes;
  } catch (e) {
    console.error("getUserListFx Error:", e);
    return [];
  }
});

export const sendEditUserFx = createEffect().use(async (payload) => {
  try {
    if (!payload.partition_key || !payload.sort_key) {
      throw new Error("Missing required fields in payload");
    }

    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "update",
          values: {
            user_id: payload.partition_key || "",
            place_id: payload.sort_key || "",
            new_start_work_time: payload.start_work_time || "",
            new_end_work_time: payload.end_work_time || "",
          },
        }),
      }
    );

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    // let result = await response.json();
    // console.log("sendEditUserFx:: result", result);

    console.log(
      "Sending payload:",
      JSON.stringify(
        {
          action: "update",
          values: {
            user_id: payload.partition_key,
            place_id: payload.sort_key,
            new_start_work_time: payload.start_work_time || "",
            new_end_work_time: payload.end_work_time || "",
          },
        },
        null,
        2
      )
    );

    let result;
    try {
      result = await response.json();
    } catch (error) {
      console.error("Error parsing response:", error);
      throw new Error("Invalid JSON response from server");
    }

    console.log("API Response:", result);

    if (result.statusCode === 200) {
      notification.success({
        message: "Success",
        description: "User working hours successfully updated",
        placement: "topRight",
      });
      return result.body;
    } else {
      throw new Error(result.body || "Unknown server error");
    }
  } catch (error) {
    console.error("Data is updated, but there is some error:", error);
    notification.error({
      message: "Error",
      description:
        error.message ||
        "Data is updated, but something went wrong on server...",
      placement: "topRight",
    });
  }
});

export const getPotentialEmpFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetPotentialEmployeesByPlaceId",
      {
        method: "POST",
        body: JSON.stringify({
          client_id: payload ? payload : "amaa25d7c9",
        }),
      }
    );

    let result = await response.json();
    return result.data;
  } catch (e) {
    console.error(e);
  }
});

export const createEmpFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        body: JSON.stringify({
          action: "create_from_potential",
          values: payload,
        }),
      }
    );

    let result = await response.json();
    console.log("sendEditUserFx:: resiult", result);
    if (result.statusCode === "200") {
      notification.success({
        message: "Success",
        description: "User successfully changed",
        placement: "topRight",
        maxCount: 1,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong...",
        placement: "topRight",
        maxCount: 1,
      });
    }
    return result.body;
  } catch (e) {
    console.error(e);
  }
});

export const createUserFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        body: JSON.stringify({
          action: "create_from_form",
          values: payload,
        }),
      }
    );

    let result = await response.json();
    console.log("sendEditUserFx:: resiult", result);
    if (result.statusCode === "200") {
      notification.success({
        message: "Success",
        description: "User successfully changed",
        placement: "topRight",
        maxCount: 1,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong...",
        placement: "topRight",
        maxCount: 1,
      });
    }
    return result.body;
  } catch (e) {
    console.error(e);
  }
});

export const createEduUserFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://ste1h7g7s7.execute-api.eu-west-1.amazonaws.com/LoyaltySystem1/UserRecords",
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );

    let result = await response.json();
    console.log("createEduUserFx:: result", result);
    return true;
  } catch (e) {
    console.error(e);
  }
});

// export const getUserListEduFx = createEffect().use(async (payload) => {
//   try {
//     const response = await fetch(
//       "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetUserListByPlaceIdEDU",
//       {
//         method: "POST",
//         body: JSON.stringify({
//           sort_key: "zasa05e1p1",
//           emp_category: payload ? payload : "Сотрудник",
//         }),
//       },
//     );

//     let result = await response.json();
//     const tempRes = JSON.parse(result.body);
//     console.log("getUserListEduFx:: Parsed body", tempRes);
//     return tempRes;
//   } catch (e) {
//     console.error(e);
//   }
// });

export const getUserListEduFx = createEffect().use(async (payload) => {
  try {
    console.log("📤 API Payload:", payload); // Log what you're sending to API

    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetUserListByPlaceIdEDU",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sort_key: payload?.sort_key || "zasa05e1p1",
          emp_category: payload?.emp_category || "Сотрудник",
        }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("❌ API Error Response:", errorText);
      throw new Error(`API Error: ${response.status} - ${errorText}`);
    }

    let result = await response.json();
    console.log("✅ API Raw Response:", result);

    if (result?.body) {
      const tempRes = JSON.parse(result.body);
      console.log("✅ API Parsed Body:", tempRes);
      return tempRes;
    } else {
      console.warn("⚠️ API Response Body is missing or empty");
      return [];
    }
  } catch (e) {
    console.error("❌ Error in getUserListEduFx:", e.message);
    return [];
  }
});

export const sendImportFilesFx = createEffect().use(async (payload) => {
  try {
    console.log("payload", payload);
    const formData = new FormData();
    formData.append("archive", payload.archive, "archive.zip");
    formData.append("excel", payload.excel, "excel_file.xlsx");
    formData.append("place_id", payload.place_id);
    const response = await fetch(payload.link, {
      method: "POST",
      body: formData,
    });
    let result = await response.json();
    return result.result;
  } catch (e) {
    console.error(e);
  }
});

export const getEduTemplateFx = createEffect().use(async () => {
  try {
    const response = await fetch(
      "https://mass-onboarding.foqus-portal.live/download-template-edu",
      {
        method: "GET",
      }
    );

    const reader = response.body.getReader();
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      } else {
        chunks.push(value);
      }
    }
    const blob = new Blob(chunks, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Edu template.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (e) {
    console.error(e);
  }
});

export const getPotentialEmpEduFx = createEffect().use(async () => {
  try {
    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetPotentialEmployeesByPlaceId",
      {
        method: "POST",
        body: JSON.stringify({
          client_id: "zasa05e1p1",
          type: "potential_employees",
        }),
      }
    );

    let result = await response.json();
    return result.data;
  } catch (e) {
    console.error(e);
  }
});

export const getTemplateFx = createEffect().use(async () => {
  try {
    const response = await fetch(
      "https://mass-onboarding.foqus-portal.live/download-template",
      {
        method: "GET",
      }
    );

    const reader = response.body.getReader();
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      } else {
        chunks.push(value);
      }
    }
    const blob = new Blob(chunks, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Template.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (e) {
    console.error(e);
  }
});
