import React, { useEffect, useMemo, useState } from "react";
import { useUnit } from "effector-react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Select,
  Button,
  Popconfirm,
  Segmented,
  Spin,
  Modal,
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import style from "./ClientUserList.module.css";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {
  $userList,
  $userListPlaceId,
  changeChosenUserEv,
  changeImportFileModalEv,
  changeShowCreateModalEv,
  changeUserListPlaceIdEv,
  fireEmployeeEv,
  getUserListFx,
} from "../../models/userListModel";
import { $userAttributes, $userGroups } from "../../models/userModel";
import UserListModal from "./UserListModal";
import CreateUserModal from "./CreateUserModal";
import ImportFilesModal from "./ImportFilesModal";
import PotentialEmp from "./PotentialEmp";

const { Option } = Select;

const ImageFromS3 = ({ presigned_url }) => {
  return (
    <img
      src={presigned_url}
      alt="User"
      style={{
        width: 70,
        height: 70,
        borderRadius: "50%",
        objectFit: "cover",
        backgroundColor: "#f0f0f0", // Fallback background
        border: "2px solid #ddd",
      }}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite error loop
        e.target.src = "/default_user_image.png"; // Fallback image
      }}
    />
  );
};

const ClientUserList = () => {
  const {
    userList,
    userListPlaceId,
    userGroups,
    userAttributes,
    loading,
    changeUserListPlaceId,
    changeShowCreateModal,
    changeImportFileModal,
  } = useUnit({
    userList: $userList,
    userListPlaceId: $userListPlaceId,
    userGroups: $userGroups,
    userAttributes: $userAttributes,
    loading: getUserListFx.pending,
    changeUserListPlaceId: changeUserListPlaceIdEv,
    changeShowCreateModal: changeShowCreateModalEv,
    changeImportFileModal: changeImportFileModalEv,
  });

  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState("0");
  useEffect(() => {
    console.log("Component mounted, fetching user list...");
    getUserListFx();
  }, []);

  useEffect(() => {
    console.log("ClientUserList - userList updated:", userList);
  }, [userList]);

  // Search, sorting, and filtering states
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  // Search handler
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Sorting function for Russian alphabet
  const sortUsers = (a, b) => {
    return sortOrder === "asc"
      ? a.user_surname.localeCompare(b.user_surname, "ru")
      : b.user_surname.localeCompare(a.user_surname, "ru");
  };

  // Filtering function
  const filteredAndSortedUsers = useMemo(() => {
    return userList
      .filter((user) =>
        `${user.user_name} ${user.user_surname}`
          .toLowerCase()
          .includes(searchTerm)
      )
      .filter((user) =>
        selectedDepartments.length > 0
          ? selectedDepartments.includes(user.emp_department)
          : true
      )
      .sort(sortUsers);
  }, [userList, searchTerm, selectedDepartments, sortOrder]);

  return (
    <div className={style.userList_wrapper}>
      {/* remove from here */}
      <div>
        {userList.length === 0 ? (
          <p>Загрузка пользователей...</p>
        ) : (
          <p>{userList.length} пользователей загружено</p>
        )}
        <h1 className={style.userList_title}>Список сотрудников</h1>
      </div>
      {/* to here */}

      <div className={style.user_list_top_wrapper}>
        <div className={style.top_buttons_wrapper}>
          {(userGroups?.includes("portal_admin") ||
            (userGroups?.includes("Customer") &&
              !userGroups?.includes("CustomerOnlyView"))) && (
            <>
              {/* <Button
                icon={<UploadOutlined />}
                onClick={changeImportFileModal}
                type="primary"
              >
                Импорт данных
              </Button> */}
              <Button
                icon={<UserAddOutlined />}
                onClick={changeShowCreateModal}
                type="primary"
              >
                Добавить сотрудника
              </Button>
            </>
          )}
        </div>
      </div>

      <div className={style.controls} style={{ marginTop: "20px" }}>
        {/* Search input */}
        <Input
          placeholder="Поиск по имени или фамилии"
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: "300px", marginRight: "10px" }}
        />

        {/* Sorting buttons */}
        <Button
          onClick={() => setSortOrder("asc")}
          type={sortOrder === "asc" ? "primary" : "default"}
        >
          А-Я сортировка по фамилии
        </Button>
        <Button
          onClick={() => setSortOrder("desc")}
          type={sortOrder === "desc" ? "primary" : "default"}
          style={{ marginRight: "10px" }}
        >
          Я-А сортировка по фамилии
        </Button>

        {/* Department filter */}
        <Select
          mode="multiple"
          placeholder="Фильтр по департаменту"
          value={selectedDepartments}
          onChange={setSelectedDepartments}
          allowClear
          style={{ width: "300px" }}
        >
          {[...new Set(userList.map((user) => user.emp_department))].map(
            (dept) => (
              <Option key={dept} value={dept}>
                {dept}
              </Option>
            )
          )}
        </Select>
      </div>

      <div
        className={style.cards_wrapper}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {loading ? (
          <Spin tip="Загрузка сотрудников..." size="large" />
        ) : (
          filteredAndSortedUsers.map((user) => (
            <div
              key={user.id}
              className={style.card}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "10px",
                width: "380px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}
            >
              {/* Photo Section */}
              <div style={{ marginRight: "10px" }}>
                <img
                  src={user.presigned_url || "/default_user_image.png"}
                  alt="User"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "3px solid #007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Modal.info({
                      title: "Просмотр изображения",
                      content: (
                        <img
                          src={user.presigned_url || "/default_user_image.png"}
                          alt="Enlarged User"
                          style={{
                            width: "100%", // Full width of the modal
                            maxHeight: "80vh",
                            objectFit: "contain",
                          }}
                        />
                      ),
                      width: "50vw", // Make modal width responsive to screen size
                      centered: true,
                    });
                  }}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loops
                    e.target.src = "/default_user_image.png"; // Set fallback image
                  }}
                />
              </div>

              <div style={{ flex: "1" }}>
                <h3
                  style={{ margin: "0 0 10px" }}
                >{`${user.user_name} ${user.user_surname}`}</h3>
                <p style={{ margin: "0 0 3px" }}>
                  <b>Департамент:</b> {user.emp_department}
                </p>
                <p style={{ margin: "0 0 3px" }}>
                  <b>Должность:</b> {user.emp_position}
                </p>
                <p style={{ margin: "0 0 3px" }}>
                  <b>Статус:</b> {user.emp_category}
                </p>
                <p style={{ margin: "0 0 3px" }}>
                  <b>Пол:</b> {user.user_gender === "Male" ? "М" : "Ж"}
                </p>
                <p style={{ margin: "0 0 3px" }}>
                  <b>Начало рабочего дня:</b> {user.start_work_time}
                </p>
                <p style={{ margin: "0 0 3px" }}>
                  <b>Конец рабочего дня:</b> {user.end_work_time}
                </p>
                {(userGroups?.includes("portal_admin") ||
                  (userGroups?.includes("Customer") &&
                    !userGroups?.includes("CustomerOnlyView"))) && (
                  <>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => changeChosenUserEv(user)}
                    />
                    {user.emp_category !== "Уволен" && (
                      <Popconfirm
                        title="Уволить сотрудника?"
                        onConfirm={() => fireEmployeeEv(user)}
                      >
                        <Button icon={<CloseCircleOutlined />} />
                      </Popconfirm>
                    )}
                  </>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      <UserListModal />
      <CreateUserModal />
      <ImportFilesModal />
    </div>
  );
};

export default ClientUserList;
