import style from "./ClientUserList.module.css";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import React, { useEffect, useMemo, useState } from "react";
import {
  $userList,
  $userListPlaceId,
  changeChosenUserEv,
  changeImportFileModalEv,
  changeShowCreateModalEv,
  changeUserListPlaceIdEv,
  fireEmployeeEv,
  getUserListFx,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { Button, Popconfirm, Segmented, Table } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
  LeftOutlined,
  RightOutlined,
  EllipsisOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import UserListModal from "./UserListModal";
import { $userAttributes, $userGroups } from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import PotentialEmp from "./PotentialEmp";
import CreateUserModal from "./CreateUserModal";
import MakeEmpModal from "./MakeEmpModal";
import PotentialImagesModal from "./PotentialEmpImagesModal";
import ImportFilesModal from "./ImportFilesModal";

export default function ClientUserList() {
  const userListPlaceId = useUnit($userListPlaceId);
  const changeUserListPlaceId = useUnit(changeUserListPlaceIdEv);
  const userList = useUnit($userList);
  const loading = useUnit(getUserListFx.pending);
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const changeImportFileModal = useUnit(changeImportFileModalEv);
  const userAttributes = useUnit($userAttributes);

  const [currentTab, setCurrentTab] = useState("0");

  const segmentedOptions = useMemo(() => {
    const initOptions = [
      {
        label: "Все",
        value: "0",
      },
    ];
    if (userGroups && !userGroups.includes("CustomerOnlyView")) {
      initOptions.push({
        label: "Потенциальные сотрудники",
        value: "1",
      });
    }
    return initOptions;
  }, [userGroups]);

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title} style={{ marginTop: "10px" }}>
        Список потенциальных сотрудников
      </div>
      <PotentialEmp />
      <UserListModal />
      <CreateUserModal />
      <MakeEmpModal />
      <PotentialImagesModal />
      <ImportFilesModal />
    </div>
  );
}
